import React, { useContext, useEffect, useRef, useState } from 'react';

import planStore from '../store/planStore';
import customerStore from '../store/customerStore';
import * as constants from '../app.constant';
import loaderStore from '../store/loaderStore';
import Layout from '../components/shared/layout';
import { graphql } from "gatsby"
import { getOrderToken, handleAmount } from '../app.utils';
import { Trans, useI18next, I18nextContext } from "gatsby-plugin-react-i18next"
import { HandleDesc, useProductDescByLang } from '../components/shared/handleDesc.component';
import queryString from 'query-string'
import deviceStore from '../store/deviceStore';
import { PlanMapping } from '../components/product/plan-selection.component';
import * as moment from 'moment'

const paymentLangMapping = {
  be_fr: "fr-be",
  be_nl: "nl-be",
  nl_nl: "nl",
  fi_fi: "fi",
  en: "en",
}

export default function Payment({ location }) {
  let url = location.href ? location.href : ''
  url = url.replace('payment/', '').replace('payment', '')
  const [isMobile, setIsMobile] = useState(false)
  const [token, setToken] = useState()
  const { navigate } = useI18next()
  const { language } = useContext(I18nextContext)
  const formRef = useRef();

  const goToBackPage = () => {
    window.history.back();
  }
  useEffect(() => {
    document.title = '';
    setIsMobile(constants.isMobile())
    if (deviceStore.voucherCode) {
      startPay()
    }
  }, [])

  useEffect(() => {
    // this will start form submit when token is available (trigger by `setToken`)
    if (token !== undefined) {
      formRef.current.submit()
      // console.log(formRef.current)
    }
  }, [token])

  const createPolicy = (param, order) => {
    const crmzPolicyReq = {
      countryCode: constants.env.GATSBY_COUNTRY,
      creationTime: moment.utc().format("HH:mm:ss"),
      coverage: null,
      custAddress: {
        address1: param.bill_address_line1,
        address2: null,
        city: param.bill_address_city,
        countryCode: constants.env.GATSBY_COUNTRY_CODE,
        postalCode: param.bill_address_postal_code,
        state: null
      },
      custMobileNumber: param.customer_mobile_no,
      customerEmail: param.customer_email,
      customerLastName: param.customer_last_name,
      customerName: param.customer_first_name,
      customerDateOfBirth: param.customer_date_of_birth,
      deviceMake: param.device_make,
      deviceModel: param.device_model,
      deviceType: param.device_type || "",
      orderId: order.order_id,
      paymentAmount: param.product_price,
      policyStartDate: moment.utc().format("DD/MM/yyyy"),
      policyPurchaseDate: moment.utc().format("DD/MM/yyyy"),
      deviceDiagnosticUrl: null,
      deviceRRP: null,
      partnerProductId: planStore.planId,
      productCode: param.product_id,
      deviceActivationDate: param.device_activation_date,
      partnerId: param.partner_code,
      productName: null,
      deviceSoldDate: moment.utc().format("DD/MM/yyyy"),
      storeCode: param.store_id,
      storeName: null,
      customerId: param.customerId,
      brokerOrderId: param.partnerOrderId,
      salesRepId: param.salesRepId,
      subscriptionFlag: true,
      sku: null,
      customerDeviceDiagnosticConsent: false,
      partnerContractId: null,
      salesChannelId: null,
      retailerName: null,
      paymentId: null,
      subscriptionType: param.subscription_type,
      policyPurchaseDateWithTime: moment.utc().format("YYYYMMDDHHmmss"),
      policyStartDateWithTime: moment.utc().format("YYYYMMDDHHmmss"),
      paymentType: null,
      valueAddedServices: null,
      voucherCode: deviceStore.voucherCode,
    }
    if (param.device_id_type === "imei") {
      crmzPolicyReq.policyIMEINumber = param.device_unique_id;
    } else {
      crmzPolicyReq.deviceSerialNo = param.device_unique_id;
    }

    const policyRequest = {
      method: 'POST',
      headers: {
        "Content-Type": constants.HEADER_REQUEST.contentType,
        "x-api-key": constants.env.GET_PRODUCT_LIST_X_API_KEY,
      },
      body: JSON.stringify(crmzPolicyReq)
    };
    // trigger create policy API
    fetch(constants.env.GET_POLICY_CREATE_URL + encodeURIComponent("Samsung Bolttech D2C"), policyRequest).then(res => res.json())
      .then(response => {
        console.log('create policy response', response.status)
      })
      .catch(err => {
        console.log("Voucher Create Policy Exception", err)
      })
  }

  function startPay() {
    loaderStore.showLoader(true)
    preparePayment()
  }

  async function preparePayment() {
    const accessToken = await getOrderToken(true);
    if (accessToken === null) {
      // TODO: not available order token, retrigger again, but this would cause overflow if api failed
      preparePayment();
      return
    }
    let productPrice = (planStore.productList.totalPremiumDue).toFixed(2)
    if (deviceStore.voucherCode) {
      productPrice = "0.00"
    } else if (planStore.productList?.promotionDiscount && planStore.productList?.discountType === constants.DISCOUNT_TYPE.MONTH_PREMIUM_FOC) {
      productPrice = "1.00"
    }
    const params = {
      store_id: "bolttech D2C",
      device_unique_id: deviceStore.deviceImei,
      plan_id: planStore.planId,
      product_id: planStore.productId,
      // "device_rrp": String(deviceStore.deviceRrp),
      device_activation_date: deviceStore.deviceActivationDate ? deviceStore.deviceActivationDate : new Date(Date.now()).toISOString(),
      payment_amount: productPrice,
      // "payment_currency": planStore.currency,
      bill_address_line1: customerStore.customerHouseNumber ? customerStore.customerHouseNumber + ', ' + customerStore.customerMailingAddress : customerStore.customerMailingAddress,
      // "bill_address_line2": "",
      bill_address_city: customerStore.customerCity,
      // "bill_address_state": "",
      bill_address_postal_code: customerStore.customerPostcode,
      customer_email: customerStore.customerEmail,
      customer_first_name: customerStore.customerFirstName,
      customer_last_name: customerStore.customerLastName,
      customer_mobile_no: customerStore.customerPhoneNo,
      customer_mobile_no_country_code: customerStore.customerPhoneNoCountryCode,
      // "customer_age_consent": customerStore.userAgeConsent,
      customer_consent: true,
      customer_date_of_birth: customerStore.customerDateOfBirth,
      // "product_price_currency": planStore.currency,
      // "product_tenure": String(planStore.productTenure),
      product_price: productPrice,
      // "product_name": planStore.productName,
      // "product_coverage_desc": planStore.coverageDesc,
      device_model: deviceStore.deviceModel,
      // "device_make": "",
      // "promo_definition": planStore.promoDefinition,
      partner_code: constants.partnerCode,
      device_id_type: PlanMapping[deviceStore.deviceType],
      subscription_type: planStore.productList.subscriptionType,
      update_status_url: constants.env.UPDATE_STATUS_URL,
      custIdNumber: customerStore.customerId,
      cid: deviceStore.cid,
    }
    if (deviceStore.voucherCode) {
      params.orderStatus = "fulfillment-pending"
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        "Authorization": `Bearer ${accessToken}`,
        "Content-Type": constants.HEADER_REQUEST.contentType,
        "x-api-key": constants.env.ORDER_X_API_KEY,
        "bolt-country-code": constants.env.GATSBY_COUNTRY,
        "bolt-tenant-id": constants.env.GATSBY_TENANT_ID,
        "bolt-language-code": constants.HEADER_REQUEST.boltLanguageCode,
        "bolt-channel": constants.HEADER_REQUEST.boltChannel
      },
      body: JSON.stringify(params)
    };
    try {
      // sample response
      // {
      //   order_id: "307554368828",
      //   created_at: "2021-11-01T06:33:36.006Z",
      //   order_status: "payment-pending",
      //   customer_id: "CUST02569762",
      //   partner_order_id: null,
      //   _status: { code: "80001", message: "Success" },
      // }
      const response = await (await fetch(constants.env.ORDER_API_URL, requestOptions)).json()

      if (
        response._status.message.toLowerCase() === "success" &&
        response._status.code === "80001"
      ) {
        planStore.updateOrderNo(response.order_id)
        planStore.updateOrderStatus(response.order_status)
        // planStore['deviceModel'] = deviceStore.deviceModel;
        localStorage.setItem(
          `orderDetail_${response.order_id}`,
          JSON.stringify(planStore)
        )
        constants.writeStorageLang(language)

        // foc voucher no need payment
        if (deviceStore.voucherCode) {
          // create crmz policy
          createPolicy(params, response)
          navigate(
            `/handlePaymentResponse?${queryString.stringify({
              status: "success",
              orderNumber: response.order_id,
            })}`
          )
          return
        }
        // only for local development
        if (process.env.NODE_ENV  === 'development') {
          let status
          if (
            window.confirm(
              `Press 'OK' for success payment, 'Cancel' for failed payment.`
            )
          ) {
            status = "success"
          } else {
            status = "fail"
          }
          navigate(
            `/handlePaymentResponse?${queryString.stringify({
              status,
              orderNumber: response.order_id,
            })}`
          )
          // skip below
          return
        }


        // sendDataToGoogleAnalytics('begin_checkout', response.order_status, '');
        // if (dfpStore.isGoogleVisitorIdExist) {
        //   // updateGuestDetailsInDFP(customerStore.customerEmail, customerStore.customerPhoneNo, null, dfpStore.guestId); //update DFP guest details
        // } else {
        //   if (window !== undefined && window.gaGlobal !== undefined) {
        //     // updateGuestDetailsInDFP(customerStore.customerEmail, customerStore.customerPhoneNo, null, dfpStore.guestId, window.gaGlobal.vid); //update DFP guest details
        //   }
        // }
        const res = await (await fetch(constants.env.PAYMENT_TOKEN_API_URL, {
          method: "post",
          headers: {
            "Content-Type": constants.HEADER_REQUEST.contentType,
          },
          body: JSON.stringify({
            client_id: constants.env.GATSBY_PAYMENT_CLIENT_ID,
            client_secret: constants.env.GATSBY_PAYMENT_CLIENT_SECRET,
            grant_type: constants.env.GATSBY_PAYMENT_GRANT_TYPE,
            scope: constants.env.GATSBY_PAYMENT_SCOPE,
          }),
        })).json()

        // as long as has access token, this should be able to submit payment
        if (res.access_token) {
          // this would trigger form submit as there is available of token
          setToken(res.access_token)
        } else {
          throw new Error('Invalid payment token fetched')
        }
        loaderStore.showLoader(false)
      } else {
        throw new Error('Invalid order api returned')
      }
    } catch (error) {
      // any error thrown inside will go to failed payment, if wan debug, put debugger on here
      loaderStore.showLoader(false)
      navigate(constants.ROUTES.PAYMENT_FAIL)
    }


  }
  const productDesc = useProductDescByLang(planStore.productList)

  return (
    <Layout header isBackBtn prevUrl={constants.ROUTES.PERSONAL_INFO}>
      { (!deviceStore.voucherCode) ?
        (isMobile ? (
            <div className="container pt-10 payment-confirm-mobile">
              <h2><Trans>payment.title</Trans></h2>
              <div className="desktop-bill-wrapper mobile">
                <div>
                  <div className="footer-header left-container mb-10">
                    <span>
                      <HandleDesc product={planStore.productList} />
                    </span>
                    <br />
                    <span>
                      (
                      <Trans
                        values={{
                          count: planStore.productList?.warranty_month,
                        }}
                      >
                        product.count-months
                      </Trans>
                      )
                    </span>
                  </div>
                  <div className="right-container">
                    <div className="price black">
                      <span className="amount">
                        {planStore.productList &&
                        <Trans values={{currency: "€", amount: handleAmount(planStore.productList.totalPremiumDue)}}>
                          product.amount
                        </Trans>
                        }
                      </span>
                      <span className="month">
                        {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?<Trans>product.method.monthly-sym</Trans>:
                          (
                            planStore.productList?.warranty_month === 12?<Trans>product.method.yearly-sym</Trans>:<Trans>product.method.yearly-sym2</Trans>
                          )}
                      </span>
                    </div>
                  </div>
                  {/* <hr /> */}

                  {!['BE','NL','FI'].includes(constants.env.GATSBY_COUNTRY) && (<>
                    <div className="price-desc lh-25 display-12">
                      <div className="left-container">
                        <span><Trans>product.price-panel.commercial</Trans></span>
                        <span><Trans>product.price-panel.acquisition</Trans></span>
                        <span><Trans>product.price-panel.admin</Trans></span>
                        <span><Trans>product.price-panel.tax</Trans></span>
                      </div>
                      <div className="right-container">
                        <span>
                          <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.commercialPremium)}}>
                            product.amount
                          </Trans>
                        </span>
                        <span>
                          <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.acquisitionCost)}}>
                            product.amount
                          </Trans>
                        </span>
                        <span>
                          <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.administrativeCost)}}>
                            product.amount
                          </Trans>
                        </span>
                        <span>
                          <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.insurancePremiumTax)}}>
                            product.amount
                          </Trans>
                        </span>
                      </div>
                    </div>

                    <hr />

                    <div className="price-total lh-25 display-12">
                      <div className="left-container">
                        <span>
                          <strong><Trans>product.price-panel.total</Trans></strong>
                        </span>
                      </div>
                      <div className="right-container">
                        <span>
                          <strong>
                            <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.totalPremiumDue)}}>
                              product.amount
                            </Trans>
                          </strong>
                        </span>
                      </div>
                    </div>
                  </>)}
                  {(
                    <>
                      <hr/>
                      <div style={{color: '#4D4D4D'}}>
                        <Trans
                          values={{
                            tax: handleAmount(planStore.productList?.insurancePremiumTax),
                            adminCosts: handleAmount(planStore.productList?.administrativeCost),
                            acquisitionCosts: handleAmount(planStore.productList?.acquisitionCost)}}>
                          product.method.oneoff.content
                        </Trans>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="payment-buttons text-center pb-20 pt-32">
                <button
                  className="btn btn-block text-center"
                  onClick={() => {
                    startPay()
                  }}
                >
                  <Trans>payment.proceed</Trans>
                </button>
                <button style={{marginTop: '10px', fontSize: '14px'}} className="dim-btn btn-block btn-width" onClick={goToBackPage}><Trans>payment.cancel</Trans></button>
              </div>
            </div>
          ) : (
            <div className="container">
              <h2 className="mb-10"><Trans>payment.title</Trans></h2>
              <div className="desktop-bill-wrapper desktop">
                <div>
                  <div className="footer-header left-container">
                    <span><HandleDesc product={planStore.productList} /></span>
                    <br />
                    <span>
                          (
                          <Trans
                            values={{
                              count: planStore.productList?.warranty_month,
                            }}
                          >
                            product.count-months
                          </Trans>
                          )
                        </span>
                  </div>
                  <div className="right-container">
                    <div>
                          <span className="">
                            {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?<Trans><Trans>product.method.monthly</Trans></Trans>:<Trans>product.method.oneoff.text</Trans>}
                          </span>
                    </div>
                    <div className="price">
                          <span className="amount">
                            {planStore.productList &&
                            <Trans values={{currency: "€", amount: handleAmount(planStore.productList.totalPremiumDue)}}>
                              product.amount
                            </Trans>
                            }
                          </span>
                      <span className="month">
                            {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?<Trans><Trans>product.method.monthly-sym</Trans></Trans>:
                              (
                                planStore.productList?.warranty_month === 12?<Trans>product.method.yearly-sym</Trans>:<Trans>product.method.yearly-sym2</Trans>
                              )}
                          </span>
                    </div>
                  </div>

                  {!['BE','NL','FI'].includes(constants.env.GATSBY_COUNTRY) && (
                    <>
                      <div className="price-desc lh-25 display-12">
                        <div className="left-container">
                            <span>
                              <Trans>product.price-panel.commercial</Trans>
                            </span>
                          <span>
                              <Trans>product.price-panel.acquisition</Trans>
                            </span>
                          <span><Trans>product.price-panel.admin</Trans></span>
                          <span><Trans>product.price-panel.tax</Trans></span>
                        </div>
                        <div className="right-container">
                            <span>
                              <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.commercialPremium)}}>
                                product.amount
                              </Trans>
                            </span>
                          <span>
                              <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.acquisitionCost)}}>
                                product.amount
                              </Trans>
                            </span>
                          <span>
                              <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.administrativeCost)}}>
                                product.amount
                              </Trans>
                            </span>
                          <span>
                              <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.insurancePremiumTax)}}>
                                product.amount
                              </Trans>
                            </span>
                        </div>
                      </div>

                      <hr />

                      <div className="price-total lh-25 display-12">
                        <div className="left-container">
                            <span>
                              <strong><Trans>product.price-panel.total</Trans></strong>
                            </span>
                        </div>
                        <div className="right-container">
                            <span>
                              <strong>
                                <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.totalPremiumDue)}}>
                                  product.amount
                                </Trans>
                              </strong>
                            </span>
                        </div>
                      </div>
                    </>
                  )}
                  {(
                    <>
                      <hr/>
                      <div style={{color: '#4D4D4D'}}>
                        <Trans
                          values={{
                            tax: handleAmount(planStore.productList?.insurancePremiumTax),
                            adminCosts: handleAmount(planStore.productList?.administrativeCost),
                            acquisitionCosts: handleAmount(planStore.productList?.acquisitionCost)}}>
                          product.method.oneoff.content
                        </Trans>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="grid-responsive2 text-center pb-20 pt-32">
                <button
                  style={{marginRight: '5px'}}
                  className="btn btn-block text-center"
                  onClick={() => {
                    startPay()
                  }}
                >
                  <Trans>payment.proceed</Trans>
                </button>
                <button style={{marginLeft: '5px'}} className="dim-btn btn-block btn-width" onClick={goToBackPage}><Trans>payment.cancel</Trans></button>
              </div>
            </div>
          )
        ) : null}
      {token && (<form ref={formRef} style={{display: 'none'}} method="POST" action={constants.env.PAYMENT_API_URL}  >
        <input name="token" defaultValue={token} />
        <input name="scopeId" defaultValue={planStore.productList.subscriptionType === "MONTHLY-INSTALLMENT"
          ? constants.env.GATSBY_PAYMENT_SCOPE_ID_MONTHLY
          : constants.env.GATSBY_PAYMENT_SCOPE_ID_ONEOFF} />
        <input name="instance" defaultValue={constants.env.GATSBY_PAYMENT_INSTANCE_ID} />
        <input name="amount" defaultValue={planStore.productList.discountType === constants.DISCOUNT_TYPE.MONTH_PREMIUM_FOC
          ? "100"
          : (planStore.productList.totalPremiumDue * 100).toFixed(0)} />
        <input name="currencyCode" defaultValue={`${constants.env.GATSBY_PAYMENT_CURRENCY_CODE}`} />
        <input name="cardHolderEmail" defaultValue={customerStore.customerEmail} />
        <input name="phone" defaultValue={customerStore.customerPhoneNo} />
        <input name="productName" defaultValue={productDesc} />
        <input name="locale" defaultValue={paymentLangMapping[language]} />
        <input name="productDescription" defaultValue={productDesc} />
        <input name="customFields1" defaultValue={planStore.orderNo} />
        <input name="customFields2" defaultValue={`${url}${constants.env.PAYMENT_REDIRECT_URL}`} />
        {/* <input name="customFields3" defaultValue={planStore.promoDefinition} /> */}
        <input name="callbackFrontendEndpoint" defaultValue={constants.env.PAYMENT_FRONTEND_CALLBACK} />
        <input name="callbackBackendEndpoint" defaultValue={constants.env.PAYMENT_BACKEND_CALLBACK} />
        <input name="paymentAccount" defaultValue={planStore.productList.subscriptionType === "MONTHLY-INSTALLMENT"
          ? constants.env.GATSBY_PAYMENT_ACCOUNT_MONTHLY
          : constants.env.GATSBY_PAYMENT_ACCOUNT_ONEOFF} />
        <input name="recurring" defaultValue={planStore.productList.subscriptionType === "MONTHLY-INSTALLMENT"
          ? 1
          : 0} />
        <input name="paymentMethod" defaultValue={constants.env.GATSBY_PAYMENT_METHOD} />
      </form>)}
    </Layout>
  )

}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
