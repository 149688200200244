import { decorate, observable, action } from "mobx";
import { create, persist } from 'mobx-persist';


class CustomerStore {
    customerFirstName = '';
    customerLastName = '';
    customerEmail = '';
    customerCountryCode = '';
    customerPhoneNo = '';
    customerPhoneNoCountryCode = '';
    customerDateOfBirth = '';
    customerMailingAddress = '';
    customerHouseNumber = '';
    customerPostcode = '';
    customerCity = '';
    userAgeConsent = '';
    userConsent = '';
    isValidatedForm = false;
    customerId = '';

    updateCustomerDetail = (customerDetail) => {
        this.customerFirstName = customerDetail.customerFirstName;
        this.customerLastName = customerDetail.customerLastName;
        this.customerEmail = customerDetail.customerEmail;
        this.customerCountryCode = customerDetail.customerCountryCode;
        this.customerPhoneNo = customerDetail.customerPhoneNo;
        this.customerPhoneNoCountryCode = customerDetail.customerPhoneNoCountryCode;
        this.customerDateOfBirth = customerDetail.customerDateOfBirth;
        this.customerMailingAddress = customerDetail.customerMailingAddress;
        this.customerHouseNumber = customerDetail.customerHouseNumber;
        this.customerPostcode = customerDetail.customerPostcode;
        this.customerCity = customerDetail.customerCity;
        this.userAgeConsent = customerDetail.userAgeConsent;
        this.userConsent = customerDetail.userConsent;
        this.isValidatedForm = customerDetail.isValidatedForm;
    }
}

decorate(CustomerStore, {
    customerFirstName: [persist, observable],
    customerLastName: [persist, observable],
    customerEmail: [persist, observable],
    customerCountryCode: [persist, observable],
    customerPhoneNo: [persist, observable],
    customerPhoneNoCountryCode: [persist, observable],
    customerDateOfBirth: [persist, observable],
    customerMailingAddress: [persist, observable],
    customerHouseNumber: [persist, observable],
    customerPostcode: [persist, observable],
    customerCity: [persist, observable],
    userAgeConsent: [persist, observable],
    userConsent: [persist, observable],
    isValidatedForm: [persist, observable],
    customerId: [persist, observable],
    updateCustomerDetail: action
})
var customerStore = new CustomerStore();
export default customerStore;

if (typeof window !== 'undefined') {
    const hydrate = create({
        storage: localStorage,
        jsonify: true
    })
    hydrate('customerStore', customerStore);
}
